#inner .listWrapper li {
  border-top: 1px solid #000;
}

#inner .listWrapper ul {
  padding: 0;
}

.headerTitle {
  margin-left: 50px;
}

.headerLine {
  height: 2px;
  background-color: #000;
  border: 0;
  margin-bottom: 10px;
}

.spinner-full {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.horizontalList {
  padding: 0;
  margin: 0;
}
.horizontalList li {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 10px;
}
.horizontalList h3 {
margin-bottom: 0px;
}
.courseList li {

}
ul.courseList {
  padding: 0;
  margin-top: 0;
}

.moveUp {
  margin: -20px 0 20px;
}
