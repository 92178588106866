.inputForm div.fullWidth{
	margin-bottom: 15px;
}

.inputForm .label, .inputForm label {
	display: block;
	margin: 20px;
	font-size: 18px;
	font-weight: bold;
}

.inputForm input {
  margin-left: 10px;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 1px;
	color: #000;
}

.inputForm input[type="text"] {
  appearance: none;
  -webkit-appearance: none;
  color: #000;
	font-weight: bold;
  font-size: 18px;
  border: 1px solid #dddddd;
  background: #ecf0f1;
  padding:5px;
  display: inline-block !important;
  visibility: visible !important;
}

.inputForm input[type="text"], focus {
  color: #000;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.inputForm input[type="number"] {
  appearance: none;
  -webkit-appearance: none;
  color: #000;
	font-weight: bold;
  font-size: 18px;
  border: 1px solid #dddddd;
  background: #ecf0f1;
  padding:5px;
  display: inline-block !important;
  visibility: visible !important;
  width: 5em;
  border-right: none;
}

.inputForm input[type="number"], focus {
  color: #000;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
/* Removes the spin button */
.inputForm input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

:focus {
  outline-color: #ddd;
}


/* Removes the clear button from date inputs */
.inputForm input[type="date"]::-webkit-clear-button {
  display: none;
}

/* Removes the spin button */
.inputForm input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

/* Always display the drop down caret */
.inputForm input[type="date"]::-webkit-calendar-picker-indicator {
  color: #2c3e50;
}

/* A few custom styles for date inputs */
.inputForm input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  color: #000;
	font-weight: bold;
  font-size: 18px;
  border:1px solid #dddddd;
  background:#ecf0f1;
  padding:5px;
  display: inline-block !important;
  visibility: visible !important;
}

.inputForm input[type="date"], focus {
  color: #000;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

/* Removes the clear button from time inputs */
.inputForm input[type="time"]::-webkit-clear-button {
  display: none;
}

/* Removes the spin button
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}*/

/* A few custom styles for date inputs */
.inputForm input[type="time"] {
  appearance: none;
  -webkit-appearance: none;
  color: #000;
	font-weight: bold;
  font-size: 18px;
  border:1px solid #dddddd;
  background:#ecf0f1;
  padding:5px;
  display: inline-block !important;
  visibility: visible !important;
}

.inputForm input[type="time"], focus {
  color: #000;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.inputForm textarea {
  appearance: none;
  -webkit-appearance: none;
  color: #000;
	font-weight: bold;
  font-size: 18px;
  border: 1px solid #dddddd;
  background: #ecf0f1;
  padding:5px;
  display: block !important;
  visibility: visible !important;
  width: 100%;
  max-width: 100%;

}

.inputForm textarea:focus {
  color: #000;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  height: 5em;
}

.inputForm span.suffix {
  background: #ecf0f1;
  border: 1px solid #dddddd;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
}

.changeData h3, .changeData p {
	margin-bottom: 0.5em;
}

.inputCheck {
  appearance: none;
  -webkit-appearance: none;
  font-size: 24px;
  height: 18px;
  width: 18px;
  background: #fff;
  border: 0.5px solid #ddd;
  vertical-align: text-bottom;
	margin-left: 0px !important;
}
.inputCheck:checked {
	background-image: url("https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/256x256/checkbox.png");
  background-size: 18px 18px;
  border: none;
}
.checkboxWrapper span {
  margin-right: 30px;
}

.checkboxWrapper {
  margin: 10px 0;
}
