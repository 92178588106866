#main {
  background-color: white;
  top: 140px;
}
.Outer {
  height: 100%;
}
.headerWrapper {
  height: 140px;
}
.App-header {
  background-color: #fff;
  height: 100px;
  padding: 20px 5%;
  color: #000;
  width: 90%;
  position: fixed;
}
#nav {
  width:20%;
  float: left;
  background-color: #f68712;
  position: fixed;
}

#inner {
  width:90%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: auto;
}

#view {
  width:80%;
  height: auto;
  overflow: auto;
  background-color: #f1f1f1;
  float: right;
  margin-bottom: 50px
}

#SearchBar {
  font-size: 18px;
}
