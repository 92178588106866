.App {
  text-align: center;
}

body, html {
  height: 100%;
  background-color: #304581;
  font-family: "Trebuchet MS", "Trebuchet", sans-serif;
  letter-spacing: 1px;
}
h1, h2 {
  font-family: "Helvetica Neue STD 77 Bold Condensed", "Helvetiva Neue", "Helvetica", sans-serif;
  margin-top: 0;
}
h3, h4, h5, h6 {
  font-family: "Trebuchet MS";
  font-weight: bold;
  margin-top: 0px;
}

pre {
  font-family: "Trebuchet MS", "Trebuchet", sans-serif;
  white-space: pre-wrap;
  word-wrap: break-word;
}


body {
  background: #304581; /* fallback for old browsers */
  background: -webkit-linear-gradient(right, #304581, #304581);
  background: -moz-linear-gradient(right, #304581, #304581);
  background: -o-linear-gradient(right, #304581, #304581);
  background: linear-gradient(to left, #304581, #304581);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-title {
  font-size: 1.5em;
  float: left;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#MainSpinner {
    text-align: center;
    background-color: #304581;
    height: 100%;
}
#Spinner {
  height: 43%;
}
