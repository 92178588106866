

button {
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15), 0 6px 20px 0 rgba(0,0,0,0.14);
  margin: 10px;
  font-weight: bold;
}
button:focus {
  outline: 0;
}
button:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.bigGreenButton {
  background-color: #4CAF50; /* Green */
  text-transform: uppercase;
  border: none;
  padding: 14px 20px;
  font-size: 16px;
  color: white;
}

i.fas.fa-times {
  /**padding: 4px 5.7px;**/
  padding: 7.5px 9.2px;
}

button.logInButton {
  margin: 0;
  font-family: "Trebuchet MS", "Trebuchet", sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  outline: 0;
  background: #009ACD;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
button.buttonlogInButton:hover,button.buttonlogInButton:hover:active,button.buttonlogInButton:hover:focus {
  background: #007ACD;
}

#LogOutButton {
  float: right;
}

#RequestWork .bigGreenButton {
  margin-left: 0;
}

.tableTable button.smallGreenButton  {
  float: right;
  width: auto;
  padding: 6px 13px;
  margin-top: 0;
  vertical-align: text-bottom;
  font-size: 14px;
}

.smallGreenButton {
  /*Extern*/
	-moz-box-shadow:inset 0px 1px 0px 0px #9acc85;
	-webkit-box-shadow:inset 0px 1px 0px 0px #9acc85;
	box-shadow:inset 0px 1px 0px 0px #9acc85;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #74ad5a), color-stop(1, #68a54b));
	background:-moz-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-webkit-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-o-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-ms-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:linear-gradient(to bottom, #74ad5a 5%, #68a54b 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#74ad5a', endColorstr='#68a54b',GradientType=0);
  background-color:#74ad5a;

	border:1px solid #3b6e22;
  border-radius: 3px;

  margin: 5px;
	color:#ffffff;
	font-size:14px;
	padding:6px 24px;
}
.smallGreenButton:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #68a54b), color-stop(1, #74ad5a));
	background:-moz-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-webkit-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-o-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-ms-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:linear-gradient(to bottom, #68a54b 5%, #74ad5a 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#68a54b', endColorstr='#74ad5a',GradientType=0);
	background-color:#68a54b;
}
.smallGreenButton:active {
	position:relative;
	top:1px;
}


.smallBlueButton {
	-moz-box-shadow:inset 0px 1px 0px 0px #54a3f7;
	-webkit-box-shadow:inset 0px 1px 0px 0px #54a3f7;
	box-shadow:inset 0px 1px 0px 0px #54a3f7;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #007dc1), color-stop(1, #0061a7));
	background:-moz-linear-gradient(top, #007dc1 5%, #0061a7 100%);
	background:-webkit-linear-gradient(top, #007dc1 5%, #0061a7 100%);
	background:-o-linear-gradient(top, #007dc1 5%, #0061a7 100%);
	background:-ms-linear-gradient(top, #007dc1 5%, #0061a7 100%);
	background:linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#007dc1', endColorstr='#0061a7',GradientType=0);
	background-color:#007dc1;

	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	border-radius:3px;
	border:1px solid #124d77;

  margin: 5px;
	color:#ffffff;
	font-size:14px;
	padding:6px 24px;
	text-shadow:0px 1px 0px #154682;
}
.smallBlueButton:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #0061a7), color-stop(1, #007dc1));
	background:-moz-linear-gradient(top, #0061a7 5%, #007dc1 100%);
	background:-webkit-linear-gradient(top, #0061a7 5%, #007dc1 100%);
	background:-o-linear-gradient(top, #0061a7 5%, #007dc1 100%);
	background:-ms-linear-gradient(top, #0061a7 5%, #007dc1 100%);
	background:linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#0061a7', endColorstr='#007dc1',GradientType=0);
	background-color:#0061a7;
}
.smallBlueButton:active {
	position:relative;
	top:1px;
}


.closeButton {
  margin: 5px;
	color: #ffffff;
	font-size: 14px;
	padding: 6px 24px;
  /*extern https://www.bestcssbuttongenerator.com/#/9*/
  background-color:#d0451b;
  -moz-box-shadow:inset 0px 1px 0px 0px #cf866c;
  -webkit-box-shadow:inset 0px 1px 0px 0px #cf866c;
  box-shadow:inset 0px 1px 0px 0px #cf866c;
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #d0451b), color-stop(1, #bc3315));
  background:-moz-linear-gradient(top, #d0451b 5%, #bc3315 100%);
  background:-webkit-linear-gradient(top, #d0451b 5%, #bc3315 100%);
  background:-o-linear-gradient(top, #d0451b 5%, #bc3315 100%);
  background:-ms-linear-gradient(top, #d0451b 5%, #bc3315 100%);
  background:linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#d0451b', endColorstr='#bc3315',GradientType=0);
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  border-radius:3px;
  border:1px solid #942911;
  display:inline-block;
  cursor:pointer;
  text-decoration:none;
  text-shadow:0px 1px 0px #854629;
}
.closeButton:hover {
  /*extern*/
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #bc3315), color-stop(1, #d0451b));
  background:-moz-linear-gradient(top, #bc3315 5%, #d0451b 100%);
  background:-webkit-linear-gradient(top, #bc3315 5%, #d0451b 100%);
  background:-o-linear-gradient(top, #bc3315 5%, #d0451b 100%);
  background:-ms-linear-gradient(top, #bc3315 5%, #d0451b 100%);
  background:linear-gradient(to bottom, #bc3315 5%, #d0451b 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#bc3315', endColorstr='#d0451b',GradientType=0);
  background-color:#bc3315;
}
.closeButton:active {
  position:relative;
	top:1px;
}
