ul {
  list-style-type: none;
}

ul.mainMenu {
    margin: 0;
    padding: 0;
    background-color: #f68712;
    height: 100%; /* Full height */
    position: relative;;; /* Make it stick, even on scroll */
    overflow: auto; /* Enable scrolling if the sidenav has too much content */
}
.mainMenu li a {
    display: block;
    color: #fff;
    padding: 20px 20px;
    text-decoration: none;
    border-bottom: 1px solid #fff;
    text-transform: uppercase;
    line-height: 1.4;
    font-size: 1.3rem;
}

/* Change the link color on hover */
.mainMenu li:hover {
    background-color: #fbb469;
}



.mainMenu .activeLi {
    background-color: #d37008;
    font-weight: bolder;
}
