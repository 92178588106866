.oneThird {
  width: 33.33333%;
  float: left;
  padding-right: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}
.fullWidth {
  width: 100%;
}

.oneSecond {
  width: 50%;
  float: left;
  padding-right: 40px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.threeFourth {
  width: 75%;
  float: left;
  padding-right: 40px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.section {
  padding: 20px 30px;
  margin-bottom: 20px;
  background-color: #fff;
}

.clearfix {
  clear: both;
}
.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.center {
  text-align: center;
}
